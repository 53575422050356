import { ComponentProps, forwardRef } from "react";

import IconWithBG from "@/components/elements/IconWithBG";
import { ConnectorOption as ConnectorOptionType } from "@/features/connectors";
import cn from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useConnectorsContext } from "../ConnectorsProvider";
import { ConnectorCard } from "./ConnectorCard";
import { ExistingConnectionSelect } from "./ExistingConnectionSelect";
import { abilityFilters } from "./constants";

export function ConnectorOption(props: {
  option: ConnectorOptionType;
  showAbilityLabels?: boolean;
}) {
  const { option } = props;

  const {
    onSelectIntegration,
    onSelectConnection,
    getExistingConnections,
    enableSelectExistingConnection,
  } = useConnectorsContext();

  const existingConnections = getExistingConnections(option.value);
  const hasExistingConnections = existingConnections.length > 0;

  const labels = [];
  if (props.showAbilityLabels) {
    option.abilities.forEach((ability) => {
      const abilityLabel = abilityFilters.find(
        (x) => x.value === ability,
      )?.label;
      if (abilityLabel) {
        labels.push(abilityLabel);
      }
    });
  }
  if (option.isPreview) {
    labels.push("Private Preview");
  }
  if (option.isBeta) {
    labels.push("Beta");
  }
  if (option.isCommunity) {
    labels.push("Community");
  }

  return (
    <ConnectorCard
      key={option.value}
      icon={<IntegrationLogoBox id={option.value} />}
      name={option.label}
      labels={labels}
      isChecked={hasExistingConnections}
      onClick={() => onSelectIntegration(option)}
    >
      {hasExistingConnections && enableSelectExistingConnection && (
        <div
          onClick={(e) => {
            // Prevent click event from bubbling up to the parent ConnectorCard
            e.stopPropagation();
            e.preventDefault();
          }}
          className="text-sm opacity-0 transition-opacity duration-300 group-focus-within:opacity-100 group-hover:opacity-100"
        >
          <ExistingConnectionSelect
            options={existingConnections ?? []}
            onChange={(connection) => {
              onSelectConnection?.(connection);
            }}
          >
            <ConnectorCardOverlayButton position="top">
              <IntegrationLogoBox id={option.integration.id} size="xs" />
              <span>
                Use existing connection{" "}
                {existingConnections.length > 1
                  ? `(${existingConnections.length})`
                  : ""}
              </span>
            </ConnectorCardOverlayButton>
          </ExistingConnectionSelect>
          <ConnectorCardOverlayButton
            position="bottom"
            onClick={(e) => {
              e.stopPropagation();
              onSelectIntegration(option);
            }}
          >
            <IconWithBG size="xs" icon={<PlusIcon className="h-5" />} />
            <span>Create new connection</span>
          </ConnectorCardOverlayButton>
        </div>
      )}
    </ConnectorCard>
  );
}

const ConnectorCardOverlayButton = forwardRef<
  HTMLDivElement,
  ComponentProps<"div"> & {
    position: "top" | "bottom";
  }
>((props, ref) => {
  const { position, children, ...rest } = props;
  return (
    <div
      ref={ref}
      role="button"
      tabIndex={0}
      className={cn(
        "absolute left-0 flex h-1/2 w-full items-center gap-4 bg-blue-50 px-5 text-gray-800 transition-all duration-300 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-200 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 dark:active:bg-gray-600",
        "group-focus-within:translate-y-0 group-hover:translate-y-0",
        position === "top" && "left-0 top-0 -translate-y-full ",
        position === "bottom" &&
          "bottom-0 left-0 translate-y-full border-t border-blue-200 dark:border-gray-500",
      )}
      {...rest}
    >
      {children}
    </div>
  );
});
