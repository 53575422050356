import React, { forwardRef } from "react";

import { Badge } from "@/components/elements/Badge";
import { ButtonCard } from "@/components/elements/ButtonCard";
import classNames from "@/helpers/classNames";
import { CheckIcon } from "@heroicons/react/24/solid";

export const ConnectorCard = forwardRef<
  HTMLButtonElement,
  {
    name: string;
    labels: string[];
    isChecked?: boolean;
    icon: React.ReactNode;
  } & React.ComponentProps<typeof ButtonCard>
>((props, ref) => {
  const { name, labels, className, children, isChecked, ...buttonProps } =
    props;
  return (
    <ButtonCard
      ref={ref}
      {...buttonProps}
      className={classNames(
        "group relative w-full items-center justify-start gap-4 rounded-sm p-4 text-left",
        className,
      )}
    >
      {props.icon}
      <div className="flex min-w-0 flex-col gap-1">
        <div className="w-full truncate text-sm font-medium leading-4 dark:text-white">
          {name}
        </div>
        {labels.length > 0 && (
          <div className="flex flex-wrap items-center gap-1">
            {labels.map((label) => {
              return (
                <Badge
                  key={label}
                  variant="outline"
                  className="whitespace-nowrap"
                >
                  {label}
                </Badge>
              );
            })}
          </div>
        )}
      </div>

      {isChecked && (
        <div className="absolute bottom-0 right-0 m-2">
          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-green-600/80 text-white">
            <CheckIcon className="h-3" />
          </div>
        </div>
      )}
      {children}
    </ButtonCard>
  );
});
